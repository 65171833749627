import { useEffect, useState } from 'react';

function useScrollProgress() {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.body.scrollHeight - window.innerHeight;
      const progressVal = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progressVal);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollProgress;
}

export default useScrollProgress;