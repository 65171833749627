import React, { useRef, useState } from 'react';
import PageLayout from '../../components/page-layout';
import axios from "axios";
import "./styles.css";
import LinearProgressWithLabel from "../../components/labeled-progress-bar";
import Box from '@mui/material/Box';
import ResultContainer from '../../components/result-container';
import useScrollProgress from '../../hooks/scrollProgress';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import TextWithNewlines from '../../components/text-format';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import useSubmitUtility from '../../hooks/useSubmitUtility';
import Alert from '../../components/alert';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-GR910T76BL')

function ImageToLyrics() {
  //backend server
  const serverUrl = window.location.origin
  const host = window.location.host

  const [result, setResult] = useState(null);
  const [taskComplete, setTaskComplete] = useState(false);
  const ws = useRef(null);
  const scrollProgress = useScrollProgress();
  const [isDragOver, setIsDragOver] = useState(false);
  const [selectedFileForm, setSelectedFileForm] = useState();
  const [fetchWorkerStatus, setFetchWorkerStatus] = useState(false);
  const fileInputRef = useRef(null);

  // to get status and progress upon submission
  const { message, isServiceOffline, progress, loading } = useSubmitUtility(fetchWorkerStatus, serverUrl, "image2lyric");
  
  //for files
  const [alertMessage, setAlertMessage] = useState(); 
  const [file, setFile] = useState();
  // Function to handle file input change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFileForm(selectedFile)
    if (selectedFile) {
      if (!['image/jpeg', 'image/png'].includes(selectedFile.type)) {
        setAlertMessage("Only jpeg/png image files are supported!");
        setAlert(true);
        return;
      }
      if (selectedFile.size / (1024 * 1024) > 5) {
        setAlertMessage("File size exceeds max limit of 5 MB");
        setAlert(true);
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        setFile(event.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const [alert, setAlert] = useState(null);

  const closeAlert = () => {
    setAlert(null);
  };
  // Function to handle drop event
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      if (!['image/jpeg', 'image/png'].includes(droppedFile.type)) {
        setAlertMessage("Only jpeg/png image files are supported!");
        setAlert(true);
        return;
      }
      if (droppedFile.size / (1024 * 1024) > 5) {
        setAlertMessage("File size exceeds max limit of 5 MB");
        setAlert(true);
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        setFile(event.target.result);
      };
      reader.readAsDataURL(droppedFile);
    }
    setSelectedFileForm(droppedFile)

  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  // Prevent the default behavior of the browser when an image is dropped
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const triggerStatusChange = () => {
    // Set to a different value
    setFetchWorkerStatus(false);

    // Use setTimeout to push the next state update to the next event loop tick
    setTimeout(() => setFetchWorkerStatus(true), 0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // for google analytics
    ReactGA.event({
      category: 'Form',
      action: 'image2lyric_generation',
      label: 'Generate Image2Lyric'
    });

    // reset values 
    triggerStatusChange();
    setResult(null);
    setTaskComplete(false);

    const formData = new FormData();
    formData.append('image', selectedFileForm);
    axios.post(`${serverUrl}/generate/image2lyric`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }).then(
        (response) => {
          if (response.status === 200) {
            // Close the previous connection if it exists
            if (ws.current) {
              // console.log("websocket closed");
              ws.current.close(3000, 'Connection closed by client');
            }
            // connect to websocket
            const websocketUrl = `${(window.location.protocol === "https:" ? "wss://" : "ws://")}${host}/${response.data.ws}`;
            ws.current = new WebSocket(websocketUrl);
            // Set up event listeners
            ws.current.onmessage = (event) => {
              const lyrics = JSON.parse(event.data);
              setResult(lyrics.text);
              setTaskComplete(true);
              setFetchWorkerStatus(false);
            }
          } else {
            setTaskComplete(true);
            setFetchWorkerStatus(false);
            setResult(`Error: Lyric generation failed with status code ${response.status}. Please report this issue to aiml@adelaide.edu.au.`);
          }
        },
        (error) => {
          setTaskComplete(true);
          setFetchWorkerStatus(false);
          setResult(`Server Error: Lyric generation failed with status code ${error.response.status}. Please report this issue to aiml@adelaide.edu.au.`);
        }
      );
  };

  return (
    <PageLayout title='Image to Lyric Generator' scrollProgress={scrollProgress} isServiceOffline={isServiceOffline} message={message}>
      {alert && <Alert message={alertMessage} onClose={closeAlert} />}
      <h1 className="main-heading">Generate Lyrics from Image</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit} className="form-section-centered" encType="multipart/form-data">
          <div className="row">
            <div className="column">
              <div className="content">
                {/* Content of the first column */}
                <div className='dropbox-container'>
                  <div
                    className={`dropzone ${isDragOver ? 'highlight' : ''}`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                  // style={{ height: "100px", width: "100px" }}
                  >
                    <div>
                      <AddPhotoAlternateOutlinedIcon />
                      <br />
                      <span className="upload-link" onClick={handleUploadClick}>Upload</span> or <br /> drop an image file here
                      <input ref={fileInputRef} name='file' type="file" accept="image/jpeg, image/png" onChange={handleFileChange} style={{ display: 'none' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="content">
                <div>
                  <p> Image Preview</p>
                  {file ? (
                    <img className="image-preview" src={file} alt="Preview" />
                  ) : (
                    <>
                      <ImageOutlinedIcon style={{ fontSize: "2rem" }} />
                      <p style={{ fontSize: "small" }}>No image selected</p>
                    </>
                  )}
                  <div className='center-align-container'>
                    <button type="submit" className={selectedFileForm ? "submit-enabled-button" : "submit-disabled-button"} disabled={!selectedFileForm}>Generate Lyrics</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {loading && !taskComplete  && !isServiceOffline &&(
        <div className='result-container'>
          Generating...
          <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={taskComplete ? 100 : progress}  />
          </Box>
        </div>
      )}
      <div>
        {result && (
          <ResultContainer result={result} containerTitle="Generated Lyrics" exportButton={false}>
            <TextWithNewlines text={result} />
          </ResultContainer>
        )}
      </div>
    </PageLayout>
  );
}

export default ImageToLyrics;