import './App.css';
import BibleGenerator from './pages/bible-generator';
import ErrorPage from './pages/error';
import HomePage from './pages/home';
import ImageToLyrics from './pages/image-to-lyrics';
import ProseGenerator from './pages/lyric-prose-generator';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from 'react';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-GR910T76BL')


function App() {
 
  const PageTracking = () => {
    const location = useLocation();
  
    useEffect(() => {
      // Track page view with react-ga4
      ReactGA.set({ page: location.pathname });
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);
  };


  return (
    <Router>
      <PageTracking/>
      <Routes>
        {/* <Route index exact element={<HomePage />} /> */}
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/bible-generator" element={<BibleGenerator />} />
        <Route exact path="/image-to-lyrics" element={<ImageToLyrics />} />
        <Route exact path="/lyric-prose-generator" element={<ProseGenerator />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
