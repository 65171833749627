import * as React from 'react';
import { Link } from 'react-router-dom'
import "./styles.css";


function CustomHeader({ children, props }) {
  return (
    <header className='header' {...props}>
      <Link className="header-link" to="/" >The <span className='header-span'>Art Project</span></Link>
    </header>
  );

}
export default CustomHeader;