import React, { useState, useEffect } from 'react';
import axios from "axios";

function useSubmitUtility(fetchWorkerStatus, serverUrl, task) {
  const [isServiceOffline, setIsServiceOffline] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  // function to manage timer
  const [progress, setProgress] = React.useState(0);
  const startProgress = () => {
    setProgress(0);
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 95) {
          clearInterval(timer);
          return prevProgress;
        }
        return prevProgress + 1;
      });
    }, 200);
  };

  // To get status
  useEffect(() => {
    const fetchStatus = async () => {
      if (fetchWorkerStatus) {
        await axios.get(`${serverUrl}/status/${task}`).then(
          (response) => {
            if (response.data.online) {
              clearInterval(intervalId);
              setMessage("Server is online");
              setIsServiceOffline(false);
              startProgress();
              setLoading(true);

            } else {
              setIsServiceOffline(true);
              setMessage("Server ready in approximately")
            }
          },
          (error) => {
            console.log("Request Error");
          }
        )
      } else {
        setIsServiceOffline(false);
      };
    }

    fetchStatus()

    // Periodically check the API every 5 seconds
    const intervalId = setInterval(fetchStatus, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);

  }, [fetchWorkerStatus, serverUrl, task]);
  return { message, isServiceOffline, progress, loading };

}

export default useSubmitUtility;