import React, { useState, useRef } from 'react';
import PageLayout from '../../components/page-layout';
import axios from "axios";
import Select from "react-select";
import "./styles.css";
import LinearProgressWithLabel from "../../components/labeled-progress-bar";
import Box from '@mui/material/Box';
import ResultContainer from '../../components/result-container';
import useScrollProgress from '../../hooks/scrollProgress';
import TextWithNewlines from '../../components/text-format';
import useSubmitUtility from '../../hooks/useSubmitUtility';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-GR910T76BL')

function ProseGenerator() {
  
  //backend server
  const serverUrl = window.location.origin
  const host = window.location.host
  
  const [result, setResult] = useState(null);
  const ws = useRef(null);
  const [fetchWorkerStatus, setFetchWorkerStatus] = useState(false);
  const scrollProgress = useScrollProgress();
  const [taskComplete, setTaskComplete] = useState(false);

  const { message, isServiceOffline, progress, loading } = useSubmitUtility(fetchWorkerStatus, serverUrl, "prose");

  const [formValues, setFormValues] = useState({
    model: "laurie",
    keywords: "",
    model_name: 'Laurie Anderson'
  });

  const modelOptions = [
    { value: "laurie", label: "Laurie Anderson", key: "model" },
    { value: "lou", label: "Lou Reed", key: "model" },
    { value: "laurie_lou", label: "Laurie & Lou", key: "model" },
    { value: "laurie_prose", label: "Laurie Prose", key: "model" },
    { value: "lou_prose", label: "Lou Prose", key: "model" }
  ];

  const setKeyValues = (key, value) => {
    setFormValues((prevProps) => ({
      ...prevProps,
      [key]: value,
    }));
  };

  const handleModelChange = (event) => {
    const { value, label } = event;
    setKeyValues("model", value);
    setKeyValues("model_name", label);

  }

  const handleGenerateKeywords = (event) => {
    event.preventDefault();
    console.log("Generate Keyword called");
    axios.get(`${serverUrl}/keywords`).then(
      (response) => {
        setKeyValues("keywords", response.data.keywords);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleInputChange = (event) => {
    setKeyValues("keywords", event.target.value);
  };

  const triggerStatusChange = () => {
    // Set to a different value
    setFetchWorkerStatus(false);

    // Use setTimeout to push the next state update to the next event loop tick
    setTimeout(() => setFetchWorkerStatus(true), 0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // for google analytics
    ReactGA.event({
      category: 'Form',
      action: 'prose_generation',
      label: 'Generate Prose'
    });

    // reset values 
    triggerStatusChange();
    setResult(null);
    setTaskComplete(false);

    axios.post(`${serverUrl}/generate/prose`, formValues).then(
      (response) => {
        if (response.status === 200) {
          // Close the previous connection if it exists
          if (ws.current) {
            // console.log("websocket closed");
            ws.current.close(3000, 'Connection closed by client');
          }
          // connect to websocket
          const websocketUrl = `${(window.location.protocol === "https:" ? "wss://" : "ws://")}${host}/${response.data.ws}`;
          ws.current = new WebSocket(websocketUrl);
          // Set up event listeners
          ws.current.onmessage = (event) => {
            const lyrics = JSON.parse(event.data);
            setResult(lyrics.text);
            setTaskComplete(true);
            setFetchWorkerStatus(false);
          }
        } else {
          setTaskComplete(true);
          setFetchWorkerStatus(false);
          setResult(`Error: Lyric generation failed with status code ${response.status}. Please report this issue to aiml@adelaide.edu.au.`);
        }
      },
      (error) => {
        setTaskComplete(true);
        setResult(`Server Error: Lyric generation failed with status code ${error.response.status}. Please report this issue to aiml@adelaide.edu.au.`);
        setFetchWorkerStatus(false);
      }
    );
  };

  return (
    <PageLayout title='Lyric and Prose Generator' scrollProgress={scrollProgress} isServiceOffline={isServiceOffline} message={message}>
      <h1 className="main-heading">Generate Lyric and Prose</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit} className="book-form">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <label style={{ marginRight: '40px' }}>Model</label>
            <Select
              options={modelOptions}
              onChange={handleModelChange}
              isDisabled={isServiceOffline}
              value={{ label: formValues.model_name }}
            />
          </div>
          <br></br>
          <div className='text-container'>
            <label htmlFor="description" className="form-label" style={{ marginRight: '15px' }}>Keywords</label>
            <button onClick={handleGenerateKeywords} className="keyword-button" disabled={isServiceOffline}>Generate Keywords</button>
            <br></br><br></br>
            <textarea
              id="description"
              name="description"
              className="text-area"
              value={formValues.keywords}
              onChange={handleInputChange}
              placeholder="Type in keywords or generate by clicking the button above"
            />
          </div>
          <div className='center-align-container'>
            <button type="submit" className={formValues.keywords ? "submit-enabled-button" : "submit-disabled-button"} disabled={!formValues.keywords}>Generate Lyrics</button>
          </div>
        </form>
      </div>
      {loading && !taskComplete  && !isServiceOffline && (
        <div className='result-container'>
          Generating...
          <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel  value={taskComplete ? 100 : progress} />
          </Box>
        </div>
      )}
      <div>
        {result && (
          <ResultContainer result={result} containerTitle="Generated Lyrics" exportButton={false}>
            <TextWithNewlines text={result} />
          </ResultContainer>
        )}
      </div>
    </PageLayout>
  );
}

export default ProseGenerator;
