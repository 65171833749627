import * as React from 'react';
import "./styles.css";
import X from "@mui/icons-material/X"
import WebIcon from '@mui/icons-material/Web';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Link } from '@mui/material';


function CustomFooter() {
  const iconStyle = {
    color: '#4b4848', // Set your desired color
    marginX: '10px',
    '&:hover': {
      color: '#000000', // Set your desired hover color
    },
  };
  return (
    <footer className='footer'>
      <div className="footer-container">
        <div className="footer-section-left">
          <img src={`${process.env.PUBLIC_URL}/aiml.svg`} alt="Description" style={{ width: '40px' }} /> 
          <div className='brand-container'> <p className='brand-text'>Australian Institute for Machine Learning</p></div>
        </div>
        <div className="footer-section-center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link href="https://www.adelaide.edu.au/aiml/" target="_blank" rel="noopener noreferrer" sx={iconStyle}>
              <WebIcon />
            </Link>
            <Link href="https://twitter.com/theaiml" target="_blank" rel="noopener noreferrer" sx={iconStyle}>
              <X />
            </Link>
            <Link href="https://au.linkedin.com/company/theaiml" target="_blank" rel="noopener noreferrer" sx={iconStyle}>
              <LinkedInIcon />
            </Link>
          </Box>
        </div>
        <div className="footer-section-right">
          <p className="footer-text"> Something wrong? Email 
            <a href='mailto:aiml@adelaide.edu.au?subject=Something wrong with the Art Project Application'> aiml@adelaide.edu.au </a>
            and let us know  <br/> This page uses 
              <a href='https://marketingplatform.google.com/about/analytics/terms/gb/'> Google Analytics</a>
          </p>
          
        </div>
      </div>

    </footer>
  );

}
export default CustomFooter;