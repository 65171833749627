import React from 'react';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined'
import './styles.css';
import LinearProcess from '../linear-process';
import Box from '@mui/material/Box';
import LoadingMessage from '../loading-messages';
import ArtificialBrainIcon from '../loading-icon';


const ServerMessage = ({ isOpen, children, isOffline }) => {
  return (
    isOpen && (
      <div className="popup">
        <div className="popup-content">
          <h1> <EnergySavingsLeafOutlinedIcon style={{ color: 'green' }} /> AI Awakening: Server Coming Online</h1>
          <p>
            We're committed to reducing carbon emissions for a greener planet.
            As part of our efforts, our server is currently shut down.
            Please wait patiently while we turn it back on. Your request will be processed once started.
          </p>
          <div className='server-message'>
            {isOffline &&  <p>{children} </p>}
            <ArtificialBrainIcon/>
            {isOffline ? (
              <div className="loading-container">
                 <Box sx={{ paddingTop: '20px', width:"100%"}}><LinearProcess /></Box>
                <LoadingMessage/>
              </div>
            ) : (
              <p>{children}</p>
            )}
            <p>
              Thank you for helping us make a positive impact on the environment.
            </p>
          </div>
          {/* 

                    <p>
                        We apologize for any inconvenience this may cause and appreciate your understanding.
                    </p> */}
          {!isOffline && <button>
            close
          </button>}
        </div>
      </div>
    )
  );
};

export default ServerMessage;
