import React from 'react';
import { Link } from 'react-router-dom';
import CustomFooter from '../../components/footer';
import CustomHeader from '../../components/header';

function ErrorPage() {
  return (
    <div>
      <CustomHeader> The Art Project </CustomHeader>
      <div className="home-container">
        <div style={{flexDirection: "column", display:'flex', alignItems: "center", justifyContent:'center'}}>
          <h1 className="main-heading">404 ERROR</h1>
          <h3 style={{fontWeight:"lighter"}}>Oops! Seems like you've stumbled into the uncharted territory of the digital abyss.
            The page you're looking for might have taken a creative sabbatical or embarked on an artistic journey to the unknown.
            But fear not, the path back to familiar lands is just a click away.</h3>
          <Link to={"/"} className='default-button'>Back to Home</Link>
        </div>

      </div>
      <CustomFooter />
    </div>
  );
}

export default ErrorPage;
