import * as React from 'react';
import ExportPDF from '../../components/pdf-generator'
import "./styles.css";

function ResultContainer({ children, title, result, containerTitle, exportButton }) {
  return (
    <div className='result-container'>
      <div className='styled-divider'>
        <div className='styled-line'></div>
        <span className='styled-text'>{containerTitle}</span>
        <div className='styled-line'></div>
      </div>
      {title && <h1 className="title">{title}</h1>}
      {children}
      {exportButton && (
        <button onClick={() => ExportPDF(result, title)}>Export to PDF</button>
      )}
    </div>
  );

}

export default ResultContainer;