import React from 'react';
import "./styles.css";

function TextWithNewlines({ text }) {
  const lines = text.split('\n');

  return (
    <div className='lyrics'>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
}

export default TextWithNewlines;