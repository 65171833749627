import React from 'react';
import { ReactComponent as BrainIcon } from "./artificial-brain.svg"

const ArtificialBrainIcon = () => {
    return (
        <div className='svg-container'>
            <BrainIcon className="svg-transition" width="80" height="80" style={{ '--dynamic-stroke-color': "#833b96" }}/>
        </div>
    );
};

export default ArtificialBrainIcon;