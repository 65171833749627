import * as React from 'react';
import CustomHeader from '../header';
import LinearProgressWithoutLabel from '../unlabeled-progress-bar';
import ServerMessage from '../server-message';
import CustomFooter from '../footer';
import Box from '@mui/material/Box';
import CountdownTimer from '../count-down';
import "./styles.css";


function PageLayout({ children, title, scrollProgress, isServiceOffline, message }) {

  return (
    <div className='content-wrap'>
      <CustomHeader> {title}</CustomHeader>
      {scrollProgress > 5 && (<Box className="sticky" sx={{ width: '100%' }}>
        <LinearProgressWithoutLabel value={scrollProgress} />
      </Box>)}
      {isServiceOffline && (<ServerMessage isOpen={true} isOffline={isServiceOffline}>{message} <CountdownTimer initialTime={120} /></ServerMessage>)}
      <div className="page-container">{children}</div>
      <CustomFooter />
    </div>

  );

}


export default PageLayout;