import React from 'react';
import { Link } from 'react-router-dom';
import "./styles.css";
import CustomFooter from '../../components/footer';
import CustomHeader from '../../components/header';

function HomePage() {
  return (
    <div>
      <CustomHeader> The Art Project </CustomHeader>
      <div className="home-container">
        <h1 className="main-heading">Machine Learning <br /> Art Projects</h1>
        <div className="sections">
          <div className="section">
            <h2>Bible Generator</h2>
            <p>Create inspiring, scripture-like verses by leveraging advanced machine learning.</p>
            <br />
            <Link to={"/bible-generator"} className='default-button'>Get Started</Link>
          </div>
          <div className="section">
            <h2>Image to Lyrics</h2>
            <p>Transform images into verses, and bring visual stories to a poetic life.</p>
            <br />
            <Link to={"/image-to-lyrics"} className='default-button'>Get Started</Link>
          </div>
          <div className="section">
            <h2>Lyric and Prose</h2>
            <p>Generate lyric and prose from keywords based on Laurie Anderson and Lou Reed style.</p>
            <br />
            <Link to={"/lyric-prose-generator"} className='default-button'>Get Started</Link>
          </div>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}

export default HomePage;
