import React, { useState, useEffect } from 'react';
import messages from './messages'; 
import "./styles.css"

const LoadingMessage = () => {
    const [currentMessage, setCurrentMessage] = useState(messages[0]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 3000); // changes every 3000 milliseconds (3 seconds)

        return () => clearInterval(intervalId); // cleanup the interval on unmount
    }, []);

    useEffect(() => {
        setCurrentMessage(messages[index]);
    }, [index]);

    return (
        <div>
            <p className='loading-message'>{currentMessage}</p>
        </div>
    );
};

export default LoadingMessage;