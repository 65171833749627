import jsPDF from 'jspdf';

const ExportPDF = (data, title) => {
  const doc = new jsPDF();
  doc.page = 1;
  // set page parameters
  const lineHeight = 5;
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  const margin = 15;
  let yPosition = 30; // Initial Y position for the first line
  const date = new Date().toDateString();

  function footer() {
    // to set content
    doc.setFontSize(8);
    doc.setTextColor(128);
    doc.text(margin, 285, `Generated by AI at ${window.location.href} on ${date}`);
    doc.text(200, 285, `${doc.page}`);
    doc.page++;
    // to set content font back
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
  };

  doc.setFontSize(14);
  doc.setTextColor(0, 0, 255);

  // To center the title
  const titleWidth = doc.getStringUnitWidth(title) * 14 / doc.internal.scaleFactor;
  const titleX = (pageWidth - titleWidth) / 2;
  doc.text(title, titleX, 20);

  // to set content
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);

  data.map((item, index) => {
    yPosition += 5;
    const lines = doc.splitTextToSize(`${item[0]}. ${item[1]}`, pageWidth - margin - margin)
    lines.forEach((line) => {
      if (yPosition + lineHeight + margin > pageHeight) {
        footer()
        doc.addPage();
        yPosition = 20;
      }

      doc.text(line, margin, yPosition);
      yPosition += lineHeight;
    });

    return item;
  });

  footer();

  const pdfBlob = new Blob([doc.output('blob')], { type: 'application/pdf' });
  const pdfUrl = URL.createObjectURL(pdfBlob);
  window.open(pdfUrl, '_blank');
}

export default ExportPDF;