import React from 'react';
import "./styles.css";

function Alert({message, onClose}) {
  return (
    <div className='popup'>
      <div className='inactive-popup-content'>
        <p> {message} </p>
        <button onClick={onClose} className='close-button'>Close</button>
      </div>
      
    </div>
  );
}

export default Alert;