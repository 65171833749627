import React, { useState, useEffect, useRef } from 'react';
import PageLayout from '../../components/page-layout';
import axios from "axios";
import Select from "react-select";
import "./styles.css";
import "../index.css"
import LinearProgressWithLabel from "../../components/labeled-progress-bar";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider'
import ResultContainer from '../../components/result-container';
import useScrollProgress from '../../hooks/scrollProgress';
import useSubmitUtility from '../../hooks/useSubmitUtility';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-GR910T76BL')

function BibleGenerator() {

  //backend server
  const serverUrl = window.location.origin
  const host = window.location.host

  const [result, setResult] = useState(null);
  const [title, setTitle] = useState(null);
  const [taskComplete, setTaskComplete] = useState(false);
  const ws = useRef(null);
  const scrollProgress = useScrollProgress();
  const [fetchWorkerStatus, setFetchWorkerStatus] = useState(false);
  const [formValues, setFormValues] = useState({
    book: "Genesis",
    chapter: 1,
    crazy: 1,
  });

  // to get status and progress upon submission
  const { message, isServiceOffline, progress, loading } = useSubmitUtility(fetchWorkerStatus, serverUrl, "bible");

  // get bible books and chapters and dynamically populate the selection list 
  const [bookOptions, setBookOptions] = useState([]); // for setting book options
  useEffect(() => {
    axios.get(`${serverUrl}/books`).then(
      (response) => {
        if (response.data) {
          Object.keys(response.data).forEach(function (key) {
            setBookOptions((prevOutput) => [
              ...prevOutput,
              {
                value: response.data[key].title,
                label: response.data[key].title,
                key: "book",
                chapters: response.data[key].chapters,
              },
            ]);
          });
        }
      })
      .catch(err => {
        console.error('Error fetching data');
      });

    // eslint-disable-next-line 
  }, []);


  // To set default chapters for genesis
  const defaultChapters = Array.from({ length: 50 }, (_, index) => ({
    key: "chapter",
    value: index + 1,
    label: index + 1
  }))

  const [chaptersOptions, setChaptersOptions] = useState(defaultChapters); // for setting book options
  // function to set selected values
  const handleSelectChange = (event) => {
    const { key, value } = event;
    setFormValues((prevProps) => ({
      ...prevProps,
      [key]: value,
    }));

    // if a book in selected, get respective chapters
    if (key === "book") {
      // reset chapter
      setFormValues((prevProps) => ({
        ...prevProps,
        chapter: 1,
      }));
      const { chapters } = event;
      setChaptersOptions([]);
      for (let i = 1; i <= chapters; i++) {
        setChaptersOptions((prevOutput) => [
          ...prevOutput,
          {
            value: i,
            label: i,
            index: i - 1,
            key: "chapter",
          },
        ]);
      }
    }
  }

  // function to set crazy value based on slider
  const setCrazyValue = (event) => {
    const { name, value } = event.target;
    setFormValues((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const triggerStatusChange = () => {
    // Set to a different value
    setFetchWorkerStatus(false);

    // Use setTimeout to push the next state update to the next event loop tick
    setTimeout(() => setFetchWorkerStatus(true), 0);
  };

  //function to handle submit - send request to backend 
  const handleSubmit = (event) => {
    event.preventDefault();

    
    ReactGA.event({
      category: 'Form',
      action: 'bible_generation',
      label: 'Generate Bible'
    });

    // reset values 
    triggerStatusChange();
    setTitle(null);
    setResult(null);
    setTaskComplete(false);

    // api call for generation 
    axios.post(`${serverUrl}/generate/bible`, formValues).then(
      (response) => {
        if (response.status === 200) {
          // Close the previous connection if it exists
          if (ws.current) {
            // console.log("websocket closed");
            ws.current.close(3000, 'Connection closed by client');
          }
          // connect to respective websocket
          const websocketUrl = `${(window.location.protocol === "https:" ? "wss://" : "ws://")}${host}/${response.data.ws}`;
          ws.current = new WebSocket(websocketUrl);
          // Set up event listeners
          ws.current.onmessage = (event) => {
            // Handle incoming messages
            const verses = JSON.parse(event.data)
            setTitle(verses.title);
            setResult(verses.text);
            setTaskComplete(true);
          };
        } else {
          setTaskComplete(true);
          setFetchWorkerStatus(false);
          setResult([["",`Error: Lyric generation failed with status code ${response.status}. Please report this issue to aiml@adelaide.edu.au.`]]);
        }
      },
      (error) => {
        setTaskComplete(true);
        setResult([["", `Server Error: Lyric generation failed with status code ${error.response.status}. Please report this issue to aiml@adelaide.edu.au.`]]);
      }
    );
  };
  return (
    <PageLayout title='Bible Generator' scrollProgress={scrollProgress} isServiceOffline={isServiceOffline} message={message}>
      <h1 className="main-heading">Generate Bible Verses</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit} className="book-form">
          <div className="form-sections">
            <div className="form-section">
              <p>Book</p>
              <br />
              <Select
                options={bookOptions}
                onChange={handleSelectChange}
                value={{ label: formValues.book }}
                isDisabled={isServiceOffline}
              />
            </div>
            <div className="form-section">
              <p>Chapter</p>
              <br />
              <Select
                options={chaptersOptions}
                onChange={handleSelectChange}
                value={{ label: formValues.chapter }}
                isDisabled={isServiceOffline}
              />
            </div>
            <div className="form-section">
              <p>Crazy-O-meter value (1 -10): {formValues.crazy}</p>
              <br />
              <Slider
                defaultValue={1}
                step={1}
                marks
                min={1}
                max={10}
                name={'crazy'}
                onChange={setCrazyValue}
                value={formValues.crazy}
                color='secondary'
                valueLabelDisplay='auto'
                disabled={isServiceOffline}
              />
            </div>
          </div>
          <div className='center-align-container'>
            <button type="submit" className="submit-button" disabled={isServiceOffline}>Generate Verses</button>
          </div>
        </form>
      </div>
      {loading && !taskComplete && !isServiceOffline && (
        <div className='result-container'>
          Generating...
          <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={taskComplete ? 100 : progress} />
          </Box>
        </div>
      )}
      <div>
        {result && (
          <ResultContainer title={title} result={result} containerTitle="Generated Text" exportButton={true}>
            {result.map((item, index) => (
              <p key={index} className='text'><span className='verse-index'> {item[0]} </span> {item[1]}</p>
            ))}
          </ResultContainer>
        )}
      </div>
    </PageLayout>
  );
}

export default BibleGenerator;